import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, InputLabel, Select, MenuItem, FormControl, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(8),
    minWidth: 250
  },
  boxElement: {
    backgroundColor: '#fff',
    padding: theme.spacing(2.5)
  }
}));

const MovieSelector = ({ movies, onChange }) => {
  const classes = useStyles();

  return (
    <FormControl variant='filled' className={classes.formControl}>
      {movies ? (
        <>
          <InputLabel id='movie-selector-label'>Movie</InputLabel>
          <Select labelId='movie-selector-label' onChange={onChange}>
            {movies.map(movie => (
              <MenuItem value={movie} key={movie}>
                {movie}
              </MenuItem>
            ))}
          </Select>
        </>
      ) : (
        <Box boxShadow={3} className={classes.boxElement}>
          <Typography>No Movies Loaded</Typography>
        </Box>
      )}
    </FormControl>
  );
};

export default MovieSelector;
