import React, { useEffect, useState } from 'react';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import './Map.css';
import { Typography } from '@material-ui/core';

const Map = ({ markers, googleMapsApiKey }) => {
  let [selectedMarker, setSelectedMarker] = useState(null);

  const handleMarkerClick = marker => {
    setSelectedMarker(marker);
  };

  const handleInfoWindowCloseClick = () => {
    setSelectedMarker(null);
  };

  useEffect(() => setSelectedMarker(null), [markers]);

  return (
    <LoadScript googleMapsApiKey={googleMapsApiKey}>
      <div className='google-map-viewport'>
        <GoogleMap mapContainerClassName='google-map-container' zoom={11} center={{ lat: 35.1, lng: -106.6 }}>
          {markers &&
            markers.map(marker => {
              return marker.geometry &&
                typeof marker.geometry.x === 'number' &&
                typeof marker.geometry.y === 'number' ? (
                <Marker
                  key={marker.attributes.OBJECTID}
                  position={{ lat: marker.geometry.y, lng: marker.geometry.x }}
                  onClick={() => handleMarkerClick(marker)}
                />
              ) : null;
            })}
          {markers && selectedMarker && (
            <InfoWindow
              position={{
                lat: selectedMarker.geometry.y + 0.017,
                lng: selectedMarker.geometry.x
              }}
              onCloseClick={handleInfoWindowCloseClick}>
              <div>
                <Typography variant='h6' gutterBottom>
                  {selectedMarker.attributes.Site}
                </Typography>
                <Typography paragraph>{selectedMarker.attributes.Address}</Typography>
                <Typography variant='caption'>
                  {new Date(selectedMarker.attributes.ShootDate).toDateString()}
                </Typography>
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      </div>
    </LoadScript>
  );
};

export default Map;
