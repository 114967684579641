import React, { Component } from 'react';
import axios from 'axios';
import MovieSelector from './MovieSelector';
import Map from './Map';
import './App.css';

class App extends Component {
  state = {
    movies: []
  };

  constructor(props) {
    super(props);
    this.filmLocationAPIUrl = props.filmLocationAPIUrl;
    this.googleMapsApiKey = props.googleMapsApiKey;
  }

  async loadFilmLocationData() {
    const filmLocationResponse = localStorage.getItem('filmLocationData') || (await axios.get(this.filmLocationAPIUrl));
    const filmLocationData = filmLocationResponse.data || JSON.parse(filmLocationResponse);

    // Here we filter for features of type Movie
    // I would LIKE to do this in the initial query to reduce the size of the response
    // but the WHERE parameter did not seem to operate how I expected, so I moved on
    const movies = filmLocationData.features.filter(feature => feature.attributes.Type === 'Movie');
    const movieTitleArray = Array.from(new Set(movies.map(movie => movie.attributes.Title)));
    movies.sort();

    this.setState({ filmLocationData, movies, movieTitleArray });

    // Save our response in local storage to reduce load on our API and greatly increase page load responsiveness
    // This data won't change that often, so this is fine
    // If needed, we could add invalidation mechanisms later
    // We do this last in case of any failures along the way
    localStorage.setItem('filmLocationData', JSON.stringify(filmLocationData));
  }

  componentDidMount() {
    this.loadFilmLocationData();
  }

  handleMovieSelected(event) {
    const selectedMovieFilmingLocations = this.state.movies.filter(
      movie => movie.attributes.Title === event.target.value
    );

    this.setState({ selectedMovieFilmingLocations });
  }

  render() {
    return (
      <>
        <Map googleMapsApiKey={this.googleMapsApiKey} markers={this.state.selectedMovieFilmingLocations}></Map>
        <MovieSelector movies={this.state.movieTitleArray} onChange={e => this.handleMovieSelected(e)}></MovieSelector>
      </>
    );
  }
}

export default App;
